import React, { Component } from "react";
import { Link } from "gatsby";
import styled from "styled-components";
import Carousel from "nuka-carousel";
import { ButtonFontCss } from "../Utils/CssStyleTemplateUtil";
import { s, d } from "../Utils/Lang";
import { StaticImage } from "./StaticImage";
import * as Widget from "./Widget";
import * as Icon from "./Icon";
import Selectors from "../Selectors";
import ActionCreator from "../ActionCreator";
import { connect } from "react-redux";

class OurPartnersSection extends Component {
  constructor(props) {
    super(props);
    this.state = {
      mount: false,
      itemPerRow: 5,
      slideIndex: 0,
    };
  }

  render() {
    let { items } = this.props;
    let { itemPerRow, slideIndex } = this.state;

    return (
      <Wrapper>
        <div className="chart-container">
          <StaticImage path={"/landing/"} name="icon-partners.png" />
        </div>
        <div className="carousel-container">
          <Carousel
            initialSlideHeight={100}
            slideIndex={this.state.slideIndex}
            afterSlide={slideIndex => this.setState({ slideIndex })}
            renderCenterLeftControls={this._renderLeftControlsIcon}
            renderCenterRightControls={this._renderRightControlsIcon}
            renderBottomCenterControls={() => false}
            style={{ outline: "none" }}
          >
            {this._reconstructItems().map((oneSlide, idx) => (
              <OneSlide key={idx}>{oneSlide}</OneSlide>
            ))}
          </Carousel>
        </div>
        <div className="row-container">
          {items.map((item, i) => (
            <div className="item">
              <StaticImage path={"/landing/"} name={item.icon} />
            </div>
          ))}
        </div>
        <Link
          to={this.props.lang === "tw" ? "/industry" : "/en/industry"}
          className="button"
        >
          {s("see-more-industry")}
        </Link>
      </Wrapper>
    );
  }

  _renderLeftControlsIcon = ({ previousSlide }) => {
    return (
      <IconContainer onClick={previousSlide}>
        <Icon.KeyboardArrowLeft size={26} color="#b2b2b2" />
      </IconContainer>
    );
  };

  _renderRightControlsIcon = ({ nextSlide }) => {
    return (
      <IconContainer onClick={nextSlide}>
        <Icon.KeyboardArrowRight size={26} color="#b2b2b2" />
      </IconContainer>
    );
  };

  _reconstructItems = () => {
    let { items } = this.props;
    let { itemPerRow } = this.state;

    let result = [];

    items.forEach((currentValue, idx) => {
      let element = (
        <div
          key={idx}
          style={{
            maxWidth: 100,
            width: "100%",
            maxHight: 100,
            height: "100%",
            margin: 15,
          }}
        >
          <StaticImage path={"/landing/"} name={currentValue.icon} />
        </div>
      );
      if (idx % itemPerRow === 0) {
        result.push([element]);
      } else {
        let pushIndex = result.length - 1;
        result[pushIndex].push(element);
      }
    });
    return result;
  };
}

const Wrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  & .chart-container {
    width: 100%;
    height: 100%;
    margin-bottom: 35px;
  }

  & .carousel-container {
    margin-bottom: 35px;
    width: 100%;
  }

  & .row-container {
    display: none;
  }

  & .button {
    border: none;
    &:hover,
    &:focus {
      outline: none;
      border: none;
    }
    max-width: 150px;
    width: 100%;
    cursor: pointer;
    padding: 12px 20px;
    color: white;
    text-align: center;
    text-decoration: none;
    background-color: #f36a26;
    font-size: 14px;
    align-self: center;
    background-image: linear-gradient(41deg, #faa34e 0%, #f36a26 95%);
    ${ButtonFontCss}
    ${props => props.css || ""};
  }

  @media screen and (max-width: 5000px) {
    /* FIXME: dirty hack to disable carousel */
    & .chart-container {
    }

    & .carousel-container {
      display: none;
    }

    & .row-container {
      width: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
      flex-wrap: wrap;

      & .item {
        margin: 20px;
        width: 100px;
        height: 100px;
      }
    }
  }
`;

const IconContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 10px;
`;

const OneSlide = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0px 20px;
`;

OurPartnersSection = connect(
  (state, ownProps) => ({
    lang: Selectors.getLang(state),
  }),
  ActionCreator
)(OurPartnersSection);

export default OurPartnersSection;
