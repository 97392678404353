import React, { Component } from "react";
import styled from "styled-components";
import { withPage } from "../PageContainer";
import {
  HeaderFontCss,
  ParagraphFontCss,
  TitleFontCssByEN,
} from "../Utils/CssStyleTemplateUtil";
import { s, d } from "../Utils/Lang";
import * as Widget from "../Components/Widget";
import ProjectCarousel from "../Components/ProjectCarousel";
import CoreValueSection from "../Components/CoreValueSection";
import OurPartnersSection from "../Components/OurPartnersSection";

const PROJECT_DESKTOP_ITEMS = [
  { img: "slide-desktop-01.jpg", home: true },
  { img: "hiprint-desktop.webp" },
  { img: "sens-desktop.webp" },
  {
    img: "slide-desktop-02.jpg",
  },
  {
    img: "slide-desktop-03.jpg",
  },
  {
    img: "slide-desktop-04.jpg",
  },
];

const PROJECT_MOBILE_ITEMS = [
  { img: "slide-mobile-01.jpg", home: true },
  { img: "hiprint-mobile.webp" },
  { img: "sens-mobile.webp" },
  {
    img: "slide-mobile-02.jpg",
  },
  {
    img: "slide-mobile-03.jpg",
  },
  {
    img: "slide-mobile-04.jpg",
  },
];

const CORE_VALUE_ITEMS = [
  {
    icon: "icon-revoluationary.png",
    highlightText: "Rev",
    text: "olutionary",
    name: "revolutionary",
  },
  {
    icon: "icon-revenue.png",
    highlightText: "Rev",
    text: "enue",
    name: "revenue",
  },
  {
    icon: "icon-revive.png",
    highlightText: "Rev",
    text: "ive",
    name: "revive",
  },
];
const OUR_PARTNERS_ITEMS = [
  { icon: "ITRI.webp" },
  { icon: "III.png" },
  { icon: "OneDegree.png" },
  { icon: "HiPrint.webp" },
  { icon: "Sensation.webp" },
  { icon: "TDSC.webp" },
  { icon: "gamma.png" },
  { icon: "Merck.webp" },
  { icon: "lr.png" },
  { icon: "seth.png" },
  { icon: "intelliGO.png" },
  { icon: "culture.png" },
  { icon: "goons.png" },
  { icon: "TST.png" },
  { icon: "Tel25.png" },
  { icon: "taipei.png" },
  { icon: "chaca.png" },
  { icon: "greenjump.png" },
  { icon: "adenovo.png" },
  { icon: "lixiang.png" },
  { icon: "WS.webp" },
  //{ icon: "yutung.png" },
  { icon: "unicorn.png" },
  { icon: "CHIG.webp" },
  { icon: "guanwuvilla.png" },
  { icon: "inigma.webp" },
  { icon: "Qritica.png" },
  { icon: "REAL.png" },
];

class LandingPage extends Component {
  state = {
    windowWidth: 0,
  };

  componentDidMount() {
    this.setState({
      windowWidth: window.innerWidth,
    });
  }

  render() {
    return (
      <Wrapper>
        {this._rowLandingPage()}
        {this._colLandingPage()}
      </Wrapper>
    );
  }

  _rowLandingPage = () => {
    return (
      <RowContainer>
        <div style={{ display: "flex", width: "100%" }}>
          <div
            className="introduction-section"
            style={{
              flex: 1,
              alignItems: "center",
              justifyContent: "flex-start",
            }}
          >
            <h2 className="text-linear-gradient">{s("landing-title")}</h2>
            <a
              className="description"
              style={{
                marginBottom: 30,
                color: "black",
                textDecoration: "none",
              }}
              href="/industry"
            >
              {s("landing-introduction")}
            </a>

            <p>{s("landing-description")}</p>
          </div>
          <div
            className="project-container"
            style={{ width: this.state.windowWidth * 0.75 }}
          >
            <ProjectCarousel
              items={PROJECT_DESKTOP_ITEMS}
              mobileItems={PROJECT_MOBILE_ITEMS}
            />
          </div>
        </div>

        <div className="home-section" style={{ paddingTop: 80 }}>
          <div className="slogan-container">
            <h2 style={{ marginBottom: 50 }}>
              {s("landing-core-value_title")}
            </h2>
            <CoreValueSection items={CORE_VALUE_ITEMS} />
          </div>
          <div className="partners-container">
            <h2 style={{ marginBottom: 30 }}>
              {s("landing-our-partners_title")}
            </h2>
            <p style={{ textAlign: "center", marginBottom: 83 }}>
              {s("landing-our-partners_introduction")}
            </p>
            <OurPartnersSection items={OUR_PARTNERS_ITEMS} />
          </div>
        </div>
      </RowContainer>
    );
  };

  _colLandingPage = () => {
    return (
      <ColContainer>
        <div className="grade-container">
          <h2 className="text-linear-gradient" style={{ marginBottom: 10 }}>
            {s("landing-title")}
          </h2>
          <a
            href="/industry"
            style={{ textDecoration: "none", color: "black", marginTop: 10 }}
          >
            {s("landing-introduction")}
          </a>
          <p
            className="description"
            style={{ maxWidth: "100%", marginTop: 20 }}
          >
            {s("landing-description")}
          </p>
        </div>
        <div className="project-container">
          <ProjectCarousel
            items={PROJECT_DESKTOP_ITEMS}
            mobileItems={PROJECT_MOBILE_ITEMS}
          />
        </div>
        <div className="slogan-container">
          <h2 style={{ marginBottom: 50 }}>{s("landing-core-value_title")}</h2>
          <CoreValueSection items={CORE_VALUE_ITEMS} />
        </div>
        <div className="partners-container">
          <h2 style={{ marginBottom: 30 }}>
            {s("landing-our-partners_title")}
          </h2>
          <p style={{ marginBottom: 30, textAlign: "center" }}>
            {s("landing-our-partners_introduction")}
          </p>
          <OurPartnersSection items={OUR_PARTNERS_ITEMS} />
        </div>
      </ColContainer>
    );
  };
}

const RowContainer = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;

  @media screen and (max-width: 900px) {
    display: none;
  }

  .introduction-section {
    background-color: #f1f1f1;
    padding: 30px;
    color: #4e4e4e;
    display: flex;
    flex-direction: column;
    flex-basis: 360px;
    flex-grow: 0;
    flex-shrink: 0;

    & h2.text-linear-gradient {
      text-align: left;
      font-style: italic;
      font-size: 30px;
      color: #4e4e4e;
      letter-spacing: 0.58px;
      line-height: 32px;
      ${TitleFontCssByEN}
      /* reference link: https://css-tricks.com/snippets/css/gradient-text/ */
      /* background-image: linear-gradient(244deg, #55c2e3 41%, #5ae6c2 93%); */
      -webkit-background-clip: text;
    }

    & p.formula {
      font-weight: 500;
      font-size: 25px;
      color: #4e4e4e;
      white-space: pre-wrap;
      letter-spacing: 0.5px;
      line-height: 30px;
      ${HeaderFontCss}
      margin-bottom: 0px;
    }

    & p.answer {
      font-weight: 500;
      font-size: 25px;
      white-space: pre;
      text-shadow: 4px 4px 6px rgba(0, 0, 0, 0.19);
      line-height: 30px;
      margin-bottom: 30px;
      ${HeaderFontCss}
      /* reference link: https://css-tricks.com/snippets/css/gradient-text/ */
      background-image: linear-gradient(22deg, #faa34e 18%, #f36a26 91%);
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
    }

    & p.data {
      min-width: 88px;
      margin-right: 46px;
      line-height: 35px;
      font-size: 25px;
      font-weight: 500;
      text-shadow: 4px 4px 6px rgba(0, 0, 0, 0.22);
      ${HeaderFontCss}
      /* reference link: https://css-tricks.com/snippets/css/gradient-text/ */
      background-image: linear-gradient(22deg, #faa34e 18%, #f36a26 91%);
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
      background-color: red;
    }

    & p.target {
      min-width: 150px;
      font-size: 25px;
      font-weight: 500;
      color: #4e4e4e;
      letter-spacing: 0.5px;
      line-height: 35px;
      ${HeaderFontCss}
    }
  }

  .home-section {
    background-color: #f1f1f1;
    flex-grow: 1;
    color: #4e4e4e;

    .project-container {
    }

    .slogan-container {
      max-width: 782px;
      width: 100%;
      margin: 0 auto 40px;
    }

    .partners-container {
      max-width: 782px;
      width: 100%;
      margin: 0 auto 40px;
    }
  }

  & h2 {
    text-align: center;
    font-size: 35px;
    color: #4e4e4e;
    letter-spacing: 0.95px;
    line-height: 40px;
    ${HeaderFontCss}
    margin-bottom: 20px;
  }

  & p {
    max-width: 500px;
    width: 100%;
    margin: 0 auto;
    font-size: 14px;
    color: #4e4e4e;
    letter-spacing: 0.4px;
    line-height: 20px;
    white-space: pre-wrap;
    ${ParagraphFontCss}
    margin-bottom: 10px;
  }
`;

const Wrapper = styled.div``;

const ColContainer = styled.div`
  background-color: #f1f1f1;

  @media screen and (min-width: ${900 + 1}px) {
    display: none;
  }

  .grade-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 30px;
    margin-bottom: 5px;

    & h2.text-linear-gradient {
      text-align: left;
      font-style: italic;
      font-weight: 800;
      font-size: 30px;
      color: #4e4e4e;
      letter-spacing: 0.58px;
      white-space: pre-wrap;
      line-height: 32px;
      ${HeaderFontCss}
      /* reference link: https://css-tricks.com/snippets/css/gradient-text/ */
      -webkit-background-clip: text;
    }

    & p.formula {
      text-align: center;
      font-weight: 500;
      font-size: 25px;
      color: #4e4e4e;
      white-space: pre-wrap;
      letter-spacing: 0.5px;
      line-height: 30px;
      ${HeaderFontCss}
    }

    & p.answer {
      text-align: center;
      font-weight: 500;
      font-size: 25px;
      white-space: pre;
      text-shadow: 4px 4px 6px rgba(0, 0, 0, 0.19);
      line-height: 30px;
      ${HeaderFontCss}
      /* reference link: https://css-tricks.com/snippets/css/gradient-text/ */
      background-image: linear-gradient(22deg, #faa34e 18%, #f36a26 91%);
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
    }

    & p.data {
      min-width: 88px;
      margin-right: 46px;
      line-height: 35px;
      font-size: 25px;
      font-weight: 500;
      text-shadow: 4px 4px 6px rgba(0, 0, 0, 0.22);
      ${HeaderFontCss}
      /* reference link: https://css-tricks.com/snippets/css/gradient-text/ */
      background-image: linear-gradient(22deg, #faa34e 18%, #f36a26 91%);
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
    }

    & p.target {
      min-width: 150px;
      font-size: 25px;
      font-weight: 500;
      color: #4e4e4e;
      letter-spacing: 0.5px;
      line-height: 35px;
      ${HeaderFontCss}
    }
  }

  .project-container {
  }

  .slogan-container {
    padding: 40px 25px 35px;
  }

  .partners-container {
    padding: 35px 55px;
  }

  .contact-container {
    padding: 35px 55px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  & h2 {
    text-align: center;
    font-size: 35px;
    color: #4e4e4e;
    letter-spacing: 0.95px;
    line-height: 40px;
    ${HeaderFontCss}
    margin-bottom: 20px;
  }

  & p {
    max-width: 500px;
    width: 100%;
    margin: 0 auto;
    font-size: 14px;
    color: #4e4e4e;
    letter-spacing: 0.4px;
    line-height: 20px;
    white-space: pre-wrap;
    ${ParagraphFontCss}
    margin-bottom: 15px;
  }

  @media screen and (max-width: 500px) {
    .logo-container {
      display: none;
    }

    .grade-container {
      padding: 52px 15px 20px;
      margin-bottom: 20px;

      & h2.text-linear-gradient {
      }

      & p.formula {
        text-align: center;
        margin-right: 0px;
      }

      & p.answer {
        text-align: center;
      }
    }

    .project-container {
      margin-bottom: 36px;
    }

    .slogan-container {
      padding: 35px 15px;
    }

    .partners-container {
      padding: 35px 15px;
    }

    .contact-container {
      padding: 15px 15px 40px;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
    }

    & h2 {
      font-size: 25px;
    }

    & p {
    }
  }
`;

export default withPage(LandingPage);
